import React from "react";
import ImageWithModal from "../../../common/ImageWithModal";
import promptTagsModalImg from "../../../../images/docs/templates/templates-prompt-tags-modal.png";
import promptTagsImg from "../../../../images/docs/templates/templates-prompt-tags.png";

const AdvancedPrompting = () => {
	return (
		<div>
			<h1 className="text-4xl font-bold mb-6">Advanced Prompting</h1>

			<p className="mb-4">
				Protocraft offers advanced prompting features that allow you to create more dynamic and flexible templates. These features include prompt tags
				and variables, which can let you create agentic prompt setups and workflows.
			</p>

			<h2 className="text-2xl font-semibold mt-8 mb-4">Prompt Tags and Variables</h2>
			<ImageWithModal src={promptTagsModalImg} alt="Prompt Tags and Variables Modal" className="mb-4" />
			<ul className="list-disc list-inside ml-4 mb-4">
				<li>
					&lt;step /&gt;: Adds a break point to a prompt or template, allowing the LLM to respond in-between each step, and then to follow up with the
					next prompt.
				</li>
				<li>&lt;llm provider="" model="" /&gt;: Specifies a specific LLM to use during a prompt or step within a prompt.</li>
				<li>&#123;&#123;PROJECT_DIRECTORY_PATH&#125;&#125;: Returns the current project's directory full path.</li>
				<li>&#123;&#123;PROJECT_DIRECTORY_STRUCTURE&#125;&#125;: Returns the current project's directory structure.</li>
				<li>&#123;&#123;PROMPT&#125;&#125;: Injects the provided user prompt at this position in the template.</li>
			</ul>

			<h2 className="text-2xl font-semibold mt-8 mb-4">Using Advanced Prompting Features</h2>
			<ImageWithModal src={promptTagsImg} alt="Using Prompt Tags" className="mb-4" />
			<p className="mb-4">To use these advanced prompting features in your templates:</p>
			<ol className="list-decimal list-inside ml-4 mb-4">
				<li>Open the Templates tab in Protocraft.</li>
				<li>Create a new template or edit an existing one.</li>
				<li>In the template editor, you can insert tags and variables directly into your prompt.</li>
				<li>Use &lt;step /&gt; tags to break your prompt into discrete steps for the AI to process.</li>
				<li>Use the &lt;llm /&gt; tag to specify which model should handle specific parts of your prompt.</li>
				<li>Insert variables like &#123;&#123;PROJECT_DIRECTORY_PATH&#125;&#125; to include dynamic content.</li>
				<li>Place the &#123;&#123;PROMPT&#125;&#125; variable where you want the user's input to be inserted.</li>
			</ol>

			<h2 className="text-2xl font-semibold mt-8 mb-4">Available Providers and Models</h2>
			<p className="mb-4">
				Protocraft supports the following providers and their respective models. (Note: This might not be up-to-date, but you can use the latest model
				IDs accessible through the Protocraft LLM selector)
			</p>

			<h3 className="text-xl font-semibold mt-6 mb-3">
				OpenAI: <code className="bg-gray-300 p-1 rounded-md font-normal text-base">openai</code>
			</h3>
			<ul className="list-disc list-inside ml-4 mb-4 text-sm">
				<li>
					<code className="bg-gray-300 p-0.5 rounded">gpt-4o-mini</code>
				</li>
				<li>
					<code className="bg-gray-300 p-0.5 rounded">gpt-4o</code>
				</li>
				<li>
					<code className="bg-gray-300 p-0.5 rounded">gpt-4</code>
				</li>
				<li>
					<code className="bg-gray-300 p-0.5 rounded">o1-preview</code>
				</li>
				<li>
					<code className="bg-gray-300 p-0.5 rounded">o1-mini</code>
				</li>
			</ul>

			<h3 className="text-xl font-semibold mt-6 mb-3">
				Anthropic: <code className="bg-gray-300 p-1 rounded-md font-normal text-base">anthropic</code>
			</h3>
			<ul className="list-disc list-inside ml-4 mb-4 text-sm">
				<li>
					<code className="bg-gray-300 p-0.5 rounded">claude-3-5-sonnet-20240620</code>
				</li>
				<li>
					<code className="bg-gray-300 p-0.5 rounded">claude-3-haiku-20240307</code>
				</li>
				<li>
					<code className="bg-gray-300 p-0.5 rounded">claude-3-opus-20240229</code>
				</li>
				<li>
					<code className="bg-gray-300 p-0.5 rounded">claude-3-sonnet-20240229</code>
				</li>
			</ul>

			<h3 className="text-xl font-semibold mt-6 mb-3">
				Google AI: <code className="bg-gray-300 p-1 rounded-md font-normal text-base">googleai</code>
			</h3>
			<ul className="list-disc list-inside ml-4 mb-4 text-sm">
				<li>
					<code className="bg-gray-300 p-0.5 rounded">gemini-2.0-flash-exp</code>
				</li>
				<li>
					<code className="bg-gray-300 p-0.5 rounded">gemini-1.5-flash-8b</code>
				</li>
				<li>
					<code className="bg-gray-300 p-0.5 rounded">gemini-1.5-flash</code>
				</li>
				<li>
					<code className="bg-gray-300 p-0.5 rounded">gemini-1.5-pro</code>
				</li>
			</ul>

			<h3 className="text-xl font-semibold mt-6 mb-3">
				OpenRouter: <code className="bg-gray-300 p-1 rounded-md font-normal text-base">openrouter</code>
			</h3>
			<p className="mb-2">OpenRouter provides access to various models from different providers. Some recommended models include:</p>
			<ul className="list-disc list-inside ml-4 mb-4 text-sm">
				<li>
					<code className="bg-gray-300 p-0.5 rounded">openrouter/auto</code>
				</li>
				<li>
					<code className="bg-gray-300 p-0.5 rounded">openai/o1-mini</code>
				</li>
				<li>
					<code className="bg-gray-300 p-0.5 rounded">openai/o1-preview</code>
				</li>
				<li>
					<code className="bg-gray-300 p-0.5 rounded">google/gemini-flash-1.5-8b</code>
				</li>
				<li>
					<code className="bg-gray-300 p-0.5 rounded">meta-llama/llama-3.1-405b</code>
				</li>
				<li>
					<code className="bg-gray-300 p-0.5 rounded">anthropic/claude-3-opus</code>
				</li>
			</ul>
			<p className="mb-4">
				Note: The available models through OpenRouter may change over time. For the most up-to-date list of supported models and their IDs, please refer
				to the OpenRouter documentation at:{" "}
				<a href="https://openrouter.ai/models" className="text-blue-600 hover:underline" target="_blank">
					https://openrouter.ai/models
				</a>
			</p>

			<h2 className="text-2xl font-semibold mt-8 mb-4">Benefits of Advanced Prompting</h2>
			<ul className="list-disc list-inside ml-4 mb-4">
				<li>Flexibility: Create more dynamic templates that can adapt to different contexts and user inputs.</li>
				<li>Precision: Use specific LLM models for different parts of your prompt to optimize performance.</li>
				<li>Structure: Break down complex prompts into manageable steps for clearer AI responses.</li>
				<li>Context: Easily include project-specific information in your prompts without manual input.</li>
			</ul>

			<p className="mt-4">
				By mastering these advanced prompting techniques and understanding the available providers and models, you can create more sophisticated and
				effective interactions with the AI in Protocraft, tailoring the experience to your specific needs and workflows.
			</p>
		</div>
	);
};

export default AdvancedPrompting;
